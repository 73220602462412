"use client";
import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";

// Make sure to register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);
const InfoSection = () => {
  useEffect(() => {
    // GSAP animation for rv-12-infos-vectors
    gsap.to("#rv-12-infos-vectors", {
      x: -400,
      duration: 0.1,
      ease: "Linear.easeNone",
      scrollTrigger: {
        trigger: "#rv-12-infos-vectors",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    // GSAP animation for rv-12-infos-vectors-2
    gsap.to("#rv-12-infos-vectors-2", {
      x: 250,
      duration: 0.1,
      ease: "Linear.easeNone",
      scrollTrigger: {
        trigger: "#rv-12-infos-vectors-2",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });
  }, []);
  return (
    <section className="rv-12-infos pt-160 pb-160">
      <div className="container">
        <div className="rv-9-section__heading">
          <CustomGsapHeaderAnimate>
            <h2 className="rv-9-section__title rv-text-anime">Core Values</h2>
          </CustomGsapHeaderAnimate>
        </div>
        <div className="row align-items-center rv-12-infos__row rv-9-guides__row justify-content-evenly">
          <div className="col-xl-4 col-lg-5 col-6 col-xxs-12">
            <div className="rv-8-service rv-9-guide rv-12-info">
              <div className="rv-9-service__icon">
                <img src="assets/img/rv-12-info-1.png" alt="Icon" />
              </div>

              <div className="rv-9-guide__txt">
                <h4 className="rv-3-service__title">
                  <a href="#">Innovation & Indigenisation</a>
                </h4>
                <p className="rv-3-service__descr">
                  We are committed to continuous innovation and pushing the
                  boundaries of what is possible with our serviceable
                  technology.
                </p>
              </div>
            </div>

            <div className="rv-8-service rv-9-guide rv-12-info">
              <div className="rv-9-service__icon">
                <img src="assets/img/rv-12-info-2.png" alt="Icon" />
              </div>

              <div className="rv-9-guide__txt">
                <h4 className="rv-3-service__title">
                  <a href="#">Integrity</a>
                </h4>
                <p className="rv-3-service__descr">
                  Encompasses honesty, ethical behaviour, and accountability,
                  serving as the foundation for trust and credibility in all
                  interactions. It is about consistently adhering to moral
                  principles
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-4 d-none d-xl-block">
            <div className="rv-9-guides__img text-center">
              <img
                src="https://ik.imagekit.io/ob62ttt1o/Hanron%20Space/Untitled%20design%20(2)_nNaDiHPULJ.png?updatedAt=1717227243340"
                alt=""
              />
            </div>
          </div>

          <div className="col-xl-4 col-lg-5 col-6 col-xxs-12">
            <div className="rv-8-service rv-9-guide rv-12-info">
              <div className="rv-9-service__icon">
                <img src="assets/img/rv-12-info-4.png" alt="Icon" />
              </div>

              <div className="rv-9-guide__txt">
                <h4 className="rv-3-service__title">
                  <a href="#">Collaboration</a>
                </h4>
                <p className="rv-3-service__descr">
                  We believe in fostering strong collaborations with
                  researchers, developers, and businesses to advance the field
                  of quantum computing.
                </p>
              </div>
            </div>

            <div className="rv-8-service rv-9-guide rv-12-info">
              <div className="rv-9-service__icon">
                <img src="assets/img/rv-12-info-5.png" alt="Icon" />
              </div>

              <div className="rv-9-guide__txt">
                <h4 className="rv-3-service__title">
                  <a href="#">Excellence</a>
                </h4>
                <p className="rv-3-service__descr">
                  We strive for excellence in everything we do, delivering
                  high-quality products and services that meet the needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rv-12-infos__vectors" id="rv-12-infos-vectors">
        <img
          src="assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
        <img
          src="assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
      </div>
      <div
        className="rv-12-infos__vectors rv-12-infos__vectors--2"
        id="rv-12-infos-vectors-2"
      >
        <img
          src="assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
        <img
          src="assets/img/rv-12-infos-vector.png"
          alt="vector"
          className="rv-12-infos-vector"
        />
      </div>
    </section>
  );
};

export default InfoSection;
