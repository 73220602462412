"use client";
import sendEmail from "@/app/api/email";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

type Inputs = {
  name: string;
  email: string;
  subject: string;
  msg: string;
  phone: string;
};

type Props = {
  innerPage?: boolean;
};

const ContactForm = ({ innerPage }: Props) => {
  const { register, handleSubmit, reset } = useForm<Inputs>();
  const [loading, setLoading] = useState(false); // Loading state
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true); // Set loading to true when submitting
    try {
      const { success, message } = await sendEmail(data);
      await sendEmail({
        ...data,
        email: "info@hanronspace.com",
      });

      if (success) {
        reset();
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after submission
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={`rv-2-contact__form ${
        innerPage ? "rv-inner-contact__form" : ""
      }`}
    >
      <div className="row">
        <div className="col-sm-6">
          <input
            type="text"
            autoComplete="off"
            id="rv-2-contact-name"
            placeholder="Your Name"
            required
            {...register("name")}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="email"
            id="rv-2-contact-email"
            placeholder="Email"
            required
            {...register("email")}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="number"
            id="rv-2-contact-email"
            placeholder="Phone Number"
            required
            {...register("phone")}
          />
        </div>
        <div className="col-sm-6">
          <input
            id="rv-2-contact-subject"
            placeholder="Enter Subject"
            required
            {...register("subject")}
          />
        </div>
        <div className="col-12">
          <textarea
            id="rv-2-contact-message"
            placeholder="Message"
            required
            {...register("msg")}
          ></textarea>
        </div>
        <div className="col-12">
          <button disabled={loading} type="submit">
            {loading ? "Sending..." : "Send Messages"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
