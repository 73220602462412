import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/banner/BannerSection2.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/form/ContactForm.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/header/HeaderSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/info/InfoSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/modal/SearchFormModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/modal/VideoModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/project/ProjectSection2.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/utils/CustomGsapHeaderAnimate.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/utils/DivAnimateYAxis.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/video/VideoSection.tsx")